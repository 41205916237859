import { Component, OnInit } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ActivatedRoute } from '@angular/router'
import { of, throwError } from 'rxjs'
import { flatMap } from 'rxjs/operators'
import { trainerMapper } from 'src/app/service/mapper/trainerMapper'
import { hunPhoneNumberValidator } from 'src/app/service/phone-number-validator'
import { Trainer } from 'src/domain/dog'

@Component({
  templateUrl: './trainer-edit.component.html',
  styleUrls: ['./trainer-edit.component.scss'],
})
export class TrainerEditComponent implements OnInit {
  constructor(private route: ActivatedRoute, private store: AngularFirestore, private auth: AngularFireAuth, private snack: MatSnackBar) {}

  trainerId: string
  trainer: Trainer
  trainerForm = new UntypedFormGroup({
    name: new UntypedFormControl(null, Validators.required),
    email: new UntypedFormControl(null, Validators.required),
    phone: new UntypedFormControl(null, [Validators.required, hunPhoneNumberValidator]),
  })

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        flatMap((param) => {
          this.trainerId = param.get('trainerId')
          if (this.trainerId) {
            return of(this.trainerId)
          } else {
            return throwError('Nincs kiképző azonosító megadva')
          }
        })
      )
      .subscribe(
        () => this.refreshTrainer(),
        (e) => console.error(e)
      )
  }

  private async refreshTrainer() {
    const data = await this.store.collection('trainers').doc(this.trainerId).get().toPromise()
    this.trainer = trainerMapper(data.data())
    this.trainerForm.reset(this.trainer)
    this.trainerForm.get('email').disable()
  }

  async saveTrainer() {
    const form = this.trainerForm.value

    const createdBy = (await this.auth.currentUser).email

    const newTrainer: Trainer = {
      ...this.trainer,
      name: form.name,
      phone: form.phone,
      createdAt: new Date(),
      createdBy,
    }

    try {
      await this.store.collection('trainers').doc(this.trainerId).set(newTrainer)
      this.snack.open('Sikeres mentés', 'Oké', { duration: 2000 })
      this.refreshTrainer()
    } catch (e) {
      console.error(e)
      this.snack.open('Hiba történt a mentés során')
    }
  }
}
