import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import * as PIXI from 'pixi.js'
import { MIPMAP_MODES, SCALE_MODES } from 'pixi.js'

@Component({
  selector: 'app-image-upload',
  templateUrl: 'image-upload.component.html',
  styleUrls: ['image-upload.component.scss'],
})
export class ImageUploadComponent implements AfterViewInit {
  app: PIXI.Application

  @ViewChild('container')
  container: ElementRef

  @Output()
  onImage = new EventEmitter<string>()

  @Input()
  width: number

  @Input()
  height: number

  imgUrl: string

  constructor() {}

  ngAfterViewInit(): void {
    this.app = new PIXI.Application({ width: this.width, height: this.height, antialias: true })
    this.container.nativeElement.appendChild(this.app.view)
  }

  async fileSelected(fileList: FileList) {
    if (fileList.length > 0) {
      const file = fileList[0]

      if (this.imgUrl) {
        URL.revokeObjectURL(this.imgUrl)
      }

      this.imgUrl = URL.createObjectURL(file)

      const texture = await PIXI.Texture.fromURL(this.imgUrl, {
        scaleMode: SCALE_MODES.LINEAR,
        mipmap: MIPMAP_MODES.ON,
      })
      const { width: imgWidth, height: imgHeight } = texture
      const imgRatio = imgWidth / imgHeight
      const imgScale = imgRatio > 1 ? imgWidth / this.width : imgHeight / this.height
      const imgScaleFactor = 1 / imgScale

      console.log(`img size w: ${imgWidth} h: ${imgHeight} ratio ${imgRatio}`)
      console.log(`bounding box (${this.width}, ${this.height}) and scaling factor ${imgScaleFactor}`)

      const img = new PIXI.Sprite(texture)
      img.scale.set(imgScaleFactor)
      // img.width = imgWidth * imgScaleFactor
      // img.height = imgHeight * imgScaleFactor

      this.app.stage.removeChildren()
      this.app.stage.addChild(img)

      this.emitImage()
    }
  }

  private emitImage() {
    // wait a little until stage is rendered and try to extract after the data
    setTimeout(() => {
      console.log(this.app.renderer.plugins)
      this.app.renderer.plugins.extract.canvas(this.app.stage).toBlob((b: Blob) => {
        const url = URL.createObjectURL(b)
        this.onImage.emit(url)
      })
    }, 100)
  }
}
