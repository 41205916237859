import { Component, ViewChild } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { Subscription } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { hunPhoneNumberValidator } from 'src/app/service/phone-number-validator'
import { Trainer } from 'src/domain/dog'

@Component({
  templateUrl: './trainer-list.component.html',
  styleUrls: ['./trainer-list.component.scss'],
})
export class TrainerListComponent {
  displayedColumns = ['index', 'name', 'phone', 'email', 'actions']
  trainerList: MatTableDataSource<Trainer> = new MatTableDataSource<Trainer>()
  trainerForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl(null, Validators.required),
    email: new UntypedFormControl(null, [Validators.email, Validators.required]),
    phone: new UntypedFormControl(null, hunPhoneNumberValidator),
  })
  showTrainerForm = false

  searchControl: UntypedFormControl = new UntypedFormControl()

  private sub = new Subscription()

  @ViewChild(MatSort, { static: true })
  sort: MatSort

  constructor(private store: AngularFirestore, private auth: AngularFireAuth, private snack: MatSnackBar) {}

  async ngOnInit() {
    await this.refreshTrainers()

    this.trainerList.sort = this.sort
    this.sort.sort({ id: 'name', start: 'asc', disableClear: false })

    this.searchControl.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe((key: string) => {
      this.trainerList.filter = key.toLocaleLowerCase()
    })
  }

  private async refreshTrainers() {
    const ts = await this.store
      .collection<Trainer>('trainers', (q) => q.orderBy('name'))
      .get()
      .toPromise()

    const trainers = ts.docs.map((doc) => doc.data() as Trainer)
    this.trainerList.data = trainers
  }

  async saveTrainer() {
    if (this.trainerForm.valid) {
      const createdAt = new Date()
      const data = this.trainerForm.value
      const trainerId = data.email
      const doc = this.store.collection<Trainer>('trainers').doc(trainerId)
      const createdBy = (await this.auth.currentUser).email
      try {
        await doc.set({
          trainerId,
          name: data.name,
          email: data.email,
          phone: data.phone,
          createdAt,
          createdBy,
        })
        this.snack.open(`${data.name} sikeresen mentve`, 'Oké', { duration: 2000 })

        this.trainerForm.reset({})
        this.showTrainerForm = false
        this.refreshTrainers()
      } catch (e) {
        console.error(e)
        this.snack.open(`${data.name} nem sikerült elmenteni`, 'Ajaj')
      }
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  toggleTrainerForm() {
    this.showTrainerForm = !this.showTrainerForm
  }
}
