<mat-card id="container">
  <mat-card-subtitle *ngIf="entry.docId">Utolsó módosítás {{entry.createdAt | date:'medium'}} - {{entry.createdBy}}
  </mat-card-subtitle>
  <form (ngSubmit)="submit()" [formGroup]="entryGroup">

    <mat-form-field appearance="fill">
      <mat-label>Edzés időpontja</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="date" [max]="maxDate">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error>Kötelező mező használd a naptár ikont.</mat-error>
      <mat-hint>Használd a kis naptár ikont</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Gyakorlat</mat-label>
      <mat-select formControlName="type" [compareWith]="compareWithType">
        <mat-option *ngFor="let t of trainingTypes" [value]="t">
          {{t.hu}} / {{ t.en}}
        </mat-option>
      </mat-select>
      <mat-error>Kötelező mező</mat-error>
    </mat-form-field>

    <div id="training-progress">
      <span id="label" class=".mat-form-field-label">Haladás</span><br />
      <mat-slider #traningProgress formControlName="progress" thumbLabel step="10" min="0" max="100"></mat-slider>
      <span id="progress-text">{{traningProgress.displayValue}} %</span>
    </div>

    <mat-form-field appearance="fill" id="comment-field">
      <mat-label>Megjegyzés</mat-label>
      <textarea matInput aria-multiline="true" matInput formControlName="comment" rows="3"></textarea>
    </mat-form-field>

    <mat-card-actions>
      <button [disabled]="isSaving || entryGroup.invalid" mat-raised-button color="primary"
        type="submit">{{entry.docId ? 'Módosítás' : 'Mentés'}}</button>
      <button [disabled]="isSaving" mat-raised-button color="warn" type="button" (click)="deleteEntry()"
        type="button">Törlés</button>
    </mat-card-actions>
  </form>
</mat-card>
