<h1>Gyakorlatok</h1>

<form id="new-training-type" [formGroup]="newType" (ngSubmit)="save()">
  <mat-form-field appearance="fill">
    <mat-label>Magyar elnevezés</mat-label>
    <input matInput formControlName="hu" placeholder="Ül">
    <mat-error>Kötelező mező</mat-error>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Angol elnevezés</mat-label>
    <input matInput formControlName="en" placeholder="Sit">
  </mat-form-field>

  <button mat-raised-button color="primary" type="submit">Mentés</button>
</form>

<mat-card *ngFor="let entry of trainingTypes" class="types">
  <mat-card-title>{{entry.hu}}</mat-card-title>
  <mat-card-subtitle>Létrehozta: {{entry.createdBy}} - {{entry.createdAt | date:'medium'}}</mat-card-subtitle>
  <mat-card-content>
    <strong>Magyar: </strong>{{entry.hu}}<br />
    <strong>Angol: </strong>{{entry.en ? entry.en : '-'}}
  </mat-card-content>
  <mat-card-actions>
    <button type="button" mat-button (click)="delete(entry)">Törlés</button>
  </mat-card-actions>
</mat-card>
